/* Modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal-main {
  position: fixed;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 75%;
  min-width: 260px;
  max-height: 223px;
  height: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.schedule-modal-main {
  position: fixed;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  min-width: 260px;
  max-height: 381px;
  height: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.all-schedule-modal-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.schedule-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.close-schedule-button-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
  width: 10%;
  height: 100%;
}
.schedule-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-schedule-button-body {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #7234e9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-schedule-button-text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.18px;
  text-align: left;
  color: #dfd9d7;
}

.title-modal {
  margin-top: 15px;
  font-family: "Exo 2", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.25px;
  text-align: center;
  color: #2a2c3c;
  margin: 0.3rem 0.5rem 0.2rem 0.5rem;
}
.body-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
}
.text-modal {
  font-family: "Exo 2", sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: center;
  color: #1d1d1d;
  margin: 0.3rem 0.5rem 0.2rem 0.5rem;
}

.img-modal {
  width: 37px;
  height: 37px;
  margin-bottom: 5%;
}

.modal-button-container {
  display: block;
  width: 100%;
  height: 50px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.modal-buttons-container {
  display: flex;
  width: 80%;
  height: 50px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.modal-button {
  width: 45%;
}

.display-block-modal {
  display: block;
}

.display-none-modal {
  display: none;
}

.title-modal-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-end;
  width: 80%;
  height: 30%;
}

.text-modal-container {
  display: flex;
  justify-content: center;
}
/* Fin Modal */

@media screen and (min-width: 480px) {
  .body-modal {
    height: 65%;
  }

  .img-modal {
    width: 54px;
    height: 54px;
  }

  .modal-main {
    width: 45%;
    height: 47%;
    max-width: 360px;
    max-height: 220px;
  }
  .schedule-modal-main {
    width: 45%;
    height: 47%;
    max-width: 360px;
    max-height: 220px;
  }
  .text-modal {
    width: 92%;
  }
  .modal-buttons-container {
    display: flex;
    width: 80%;
    height: 50px;
    margin: 0 auto;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}

@media screen and (min-width: 760px) {
  .body-modal {
    height: 75%;
  }
  .title-modal-container {
    height: 25%;
  }
  .img-modal {
    width: 54px;
    height: 54px;
  }
  .title-modal {
    font-size: 22px;
    margin: 0.3rem 0.5rem 0.2rem 0.5rem;
  }

  .text-modal {
    font-size: 16px;
  }

  .schedule-image {
    width: 653px;
    height: 551px;
    object-fit: contain;
  }

  .close-schedule-button-body {
    width: 25px;
    height: 25px;
  }
  .modal-main {
    width: 45%;
    height: 47%;
    max-width: 400px;
    max-height: 300px;
  }
  .schedule-modal-main {
    width: 45%;
    height: 47%;
    min-width: 625px;
    min-height: 525px;
  }
}
