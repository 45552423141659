.transaction-filter-option-main-container {
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.title-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.filter-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.transaction-filter-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: left;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
  margin-left: 12%;
}

.transaction-filter-option-row-container {
  display: flex;
  flex-direction: row;
  flex: 1 0 100%;
  justify-content: space-around;
}

.option-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.radio-button-option-style {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 5px;
  margin-left: 30%;
}

.filter-option-label-style {
  font-family: "Exo 2", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .transaction-filter-option-main-container {
    margin-top: -50px;
  }
  .transaction-filter-option-row-container {
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: center;
  }
  .transaction-filter-title {
    margin-left: 0;
    font-size: 16px;
  }
  .radio-button-option-style {
    margin-left: 0;
  }
  .filter-option-label-style {
    font-size: 14px;
  }
  .option-container {
    width: 120px;
  }
  .radio-button-option-style {
    width: 24px;
    height: 24px;
  }
}
