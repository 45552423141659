.operations-container {
  margin: 0 auto;
  height: 98%;
}

.operations-title-container {
  margin-top: 15px;
  display: flex;
  width: 100%;
  flex-direction: row;
}

.operations-title {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
  margin-left: 2.5vw;
}

.cashier-table-container {
  margin-top: 10px;
  padding: 0 3% 0 3%;
}

.cashier-transactions-filter-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
}

.details-icon-style {
  width: 14.9px;
  height: 9.9px;
  object-fit: contain;
  cursor: pointer;
}

.edit-blue-icon-style {
  width: 14.9px;
  height: 12.5px;
  object-fit: contain;
  cursor: pointer;
  margin-left: 10px;
}

.edit-icon-disabled {
  cursor: unset;
}

.search-filter-button {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-left: 15px;
}

.dialog-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-title {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
}

.transaction-dialog-row-item {
  display: flex;
  flex-direction: row;
  width: 570px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}

.transaction-dialog-label {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #262323;
  margin-left: 45px;
}

.transaction-dialog-bold {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #262323;
  margin-right: 60px;
}

.transaction-dialog-value {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
  margin-right: 60px;
}

.status-different-color {
  font-weight: bold;
  color: #7234e9;
}
