.landing-container {
  width: 100%;
  margin: 0 auto;
  background-image: url("../../../assets/img/fondo-azul.png");
  background-size: auto;
  background-repeat: initial;
  background-position: center center;
  animation-name: displacement;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.body-content {
  /* margin: 2rem auto 0 auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.landing-header-container {
  width: 100%;
  margin-bottom: 100px;
}

.landing-main-container {
  width: 1019px;
}

.landing-aboutUs-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  background-image: url("../../../assets/img/fondo-blanco.png");
  background-size: auto;
  background-position-y: -300px;
  background-position-x: -225px;
  animation-name: displacement;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  scroll-behavior: smooth;
}
.landing-footer-container {
  width: 100%;
  background-color: #1d1d1d;
}

.chat-button {
  text-decoration: none;
  width: 90px;
  height: 50px;
  position: absolute;
  margin-top: -175px;
  margin-left: 78%;
}

.chat-button-1 {
  width: 85.9px;
  height: 46.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(114, 52, 233, 0.46);
  border-radius: 50px 50px 50px 50px;
}

.chat-button-2 {
  width: 79.3px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(114, 52, 233, 0.71);
  border-radius: 50px 50px 50px 50px;
}

.chat-button-3 {
  width: 72.8px;
  height: 35.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7234e9;
  border-radius: 50px 50px 50px 50px;
  font-family: "Exo 2", sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

@keyframes displacement {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
}

@media screen and (min-width: 992px) {
  .chat-button {
    margin-top: 0;
    margin-left: 0;
    position: fixed;
    top: 83%;
    right: 0;
    bottom: 0;
    left: 90%;
  }
}
