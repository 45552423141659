.checkEmail-container {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.checkEmail-header {
  display: block;
  text-align: center;
  margin-top: -80px;
  margin-bottom: 80px;
}

.checkEmail-logo {
  width: 120.6px;
  height: 87.3px;
}

.title-checkEmail {
  width: 279px;
  height: 48px;
  font-family: "Exo 2", sans-serif;
  font-size: 25px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.title-container-checkEmail {
  width: 97%;
  max-width: 307px;
  min-width: 299px;
  height: 40px;
  border-radius: 36px;
  background-color: #7234e9;
  text-align: center;
}

.title-shadow-container-checkEmail {
  width: 95%;
  max-width: 321px;
  min-width: 308px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  background-color: rgba(114, 52, 233, 0.24);
}

.checkEmail-content-container {
  margin-top: 25px;
  padding: 5px;
  font-family: "Exo 2", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.16px;
  color: white;
  text-align: center;
}

.text-blue-checkEmail {
  color: #ebff00;
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .checkEmail-container {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .checkEmail-header {
    display: block;
    text-align: center;
    margin-top: -175px;
    margin-bottom: 100px;
  }

  .title-checkEmail {
    width: 279px;
    height: 48px;
    font-family: "Exo 2", sans-serif;
    font-size: 35px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
  }

  .title-container-checkEmail {
    height: 49px;
    border-radius: 36px;
    text-align: center;
  }

  .title-shadow-container-checkEmail {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    background-color: rgba(114, 52, 233, 0.24);
  }
  .pointer-checkEmail {
    cursor: pointer;
  }
  .checkEmail-content-container {
    margin-top: 25px;
    font-family: "Exo 2", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: white;
    text-align: center;
  }
}
