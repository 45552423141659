.table-main-container {
  margin-top: 30px;
}
.eye-icon-style {
  width: 14.9px;
  height: 9.9px;
  object-fit: contain;
  cursor: pointer;
}

.refresh-icon-style {
  width: 18px;
  height: 18px;
  object-fit: contain;
  cursor: pointer;
}

.dialog-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-title {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
}
.transaction-table-row-item {
  display: flex;
  flex-direction: row;
  width: 570px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}
.transaction-table-label {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #262323;
  margin-left: 45px;
}
.transaction-table-value {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
  margin-right: 60px;
}

.transaction-table-bold {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #262323;
  margin-right: 60px;
}

.status-different-color {
  font-weight: bold;
  color: #7234e9;
}
