.profile-user-container {
  width: 100%;
}

.profile-user-logo {
  width: 215px;
  height: 65px;
}

.profile-user-identification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profile-user-identification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 290px;
  margin-bottom: 15px;
}

.profile-user-text-identification {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  color: #ebff00;
}

.profile-user-icon {
  width: 22.9px;
  height: 22.9px;
  margin-right: 10px;
}

.subTitle-profile-user {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fdfbfb;
  margin-top: 20px;
  margin-bottom: 15px;
}

.inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.profile-user-dropdown-container {
  display: flex;
  min-width: 270px;
  max-width: 270px;
  align-items: center;
}

.profile-password-container {
  margin-top: -12px;
}

.profile-date-picker-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  max-width: 326px;
  min-width: 280px;
  height: 40px;
  margin-bottom: 40px;
  background-color: rgba(227, 227, 227, 0.35);
  border: none;
  border-bottom: 1px solid white;
  border-radius: 2px 2px 0px 0px;
  padding-left: 10px;
  color: white;
  box-shadow: none;
  outline: none;
}

.white-icon ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.profile-date-picker-invalid {
  border-bottom: 1px solid red;
}

.profile-date-picker-valid {
  border-bottom: 1px solid #ebff00;
}

.profile-text-date-picker {
  font-family: "Exo 2", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.2; */
  letter-spacing: 0.2px;
  text-align: center;
  color: #bababa;
}

.profile-gender-container {
  margin-top: 30px;
  margin-bottom: 40px;
}

.profile-edit-container {
  position: absolute;
  margin-top: 8px;
  margin-left: 300px;
}

.profile-user-edit {
  width: 15px;
  height: 13px;
  cursor: pointer;
}

.text-terms-profile-user {
  margin-top: 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
}

.link-text-terms-profile-user {
  text-decoration: none;
  color: #2ccbea;
}

.text-light-terms-profile-user {
  color: #ffffff;
}

.error-profile-user {
  max-width: 270px;
  margin: 0 auto;
}

.error-text-profile-user {
  margin-top: 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #fd2d00;
  text-align: center;
}
.button-profile-user {
  max-width: 270px;
  margin: 0 auto;
  margin-top: 30px;
}

.profile-user-check-circle {
  width: 12.2px;
  height: 12.2px;
  margin-right: 10px;
}

.profile-user-help-circle {
  width: 12.2px;
  height: 12.2px;
  padding-left: 2px;
  padding-right: 6px;
}

.error-profile-user-container {
  margin-top: -40px;
  height: 20px;
}

.error-profile-user-container-aux {
  margin-top: -49px;
  height: 20px;
  margin-bottom: 8px;
}

.error-text-profile-user-container {
  font-family: "Exo 2", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #fd2d00;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: -20px;
}

@media screen and (min-width: 992px) {
  .inputs-container {
    width: 740px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .text-terms-profile-user {
    padding-left: 20px;
  }

  .profile-user-check-circle {
    width: 18px;
    height: 18px;
    position: absolute;
    margin-left: -30px;
  }

  .profile-user-identification {
    width: 650px;
    margin-bottom: 25px;
  }
  .profile-user-text-identification {
    font-size: 20px;
  }

  .profile-user-icon {
    width: 42.2px;
    height: 42.2px;
  }
}
