.edit-operation-container {
  margin: 0 auto;
  height: 85.5vh;
}

.edit-operation-title-container {
  margin-left: 2.5vw;
}

.edit-operation-info {
  margin-top: -20px;
}
.edit-operation-title {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
}

.edit-operation-subtitle {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: center;
  color: #1d1d1d;
}

.text-bold-edit-operation {
  font-weight: 600;
}

.text-color-edit-operation {
  color: #7234e9;
}

.operation-right-margin {
  margin-right: 10px;
}

.btc-left-margin {
  margin-left: 40px;
}
.margin-top-from-title {
  margin-top: 20px;
  margin-bottom: 40px;
}

.margin-top-from-amount {
  margin-top: 20px;
}
.edit-operation-data-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.edit-operation-column-container {
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.edit-operation-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: 50px;
  align-items: center;
}

.edit-operation-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-operation-button {
  width: 100%;
  max-width: 250px;
  margin-top: 30px;
}

.edit-operation-dropdown {
  min-width: 150px;
}

.edit-operation-textField {
  width: 150px;
  height: 30px;
}

.vd-help-text-container {
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin-left: 40%;
}

.vd-help-text {
  font-family: "Exo 2", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  color: #7234e9;
}
