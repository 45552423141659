.home-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0 0;
  font-family: "Exo 2", sans-serif;
}

.main-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #7234e9;
  color: #ffffff;
  height: 3.2rem;
  border-radius: 63px;
  object-fit: contain;
  border: none;
  outline-style: none;
  max-width: 60%;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  cursor: pointer;
}

.secondary-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #ffffff;
  height: 3rem;
  border-radius: 63px;
  object-fit: contain;
  border: solid 6px rgba(114, 52, 233, 0.35);
  outline-style: none;
  max-width: 58%;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 992px) {
  /* .home-button-container {
    margin: 1.5rem 1rem 1rem 1.5rem;
  } */
  .main-button {
    max-width: 30%;
    height: 3rem;
    font-size: 25px;
  }

  .secondary-button {
    max-width: 28%;
    height: 2.7rem;
    font-size: 20px;
  }
}
