.full-filter-component-container {
  display: flex;
  flex-direction: column;
}

.filter-label-container {
  width: 175px;
  height: 50px;
  border-radius: 3px;
  background-color: rgba(209, 206, 206, 0.3);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label-div-container {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}

.clear-image-container {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.clear-date-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.clear-none-container {
  display: none;
}

.clear-filter-button {
  width: 12.5px;
  height: 12.5px;
  margin-top: 5px;
  margin-right: 10px;
  object-fit: contain;
}

.filter-label-style {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  text-align: center;
  color: #1d1d1d;
  margin-left: 5px;
}

.status-filter-container-margin {
  margin-left: 2.5vw;
  margin-right: 7.5px;
  justify-content: space-between;
}

.date-filter-container-margin {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.statuses-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 55px;
  width: 170px;
  margin-left: 2.5vw;
  z-index: 100000;
}

.status-list-row {
  display: flex;
  flex-direction: row;
  background-color: #d1cece;
  align-items: center;
  width: 170px;
  height: 25px;
  cursor: pointer;
}

.status-list-label {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  text-align: left;
  color: #23232d;
  margin-left: 5px;
}

.list-label-container {
  display: flex;
  height: 100%;
  width: 80%;
  align-items: center;
}

.list-check-container {
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
}

.check-filter-button {
  width: 12.5px;
  height: 12.5px;
  object-fit: contain;
}
