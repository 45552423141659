.home-cashier-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.home-cashier-content-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 73%;
}

@media screen and (min-width: 1300px) {
}
