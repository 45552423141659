.header-cashier-container {
  width: 100%;
  height: 12vh;
  display: flex;
  flex-direction: row;
}

.header-cashier-left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 10%;
}

.header-cashier-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 80%;
}

.header-cashier-logo {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 25px;
  width: 100.6px;
  height: 70.3px;
  cursor: pointer;
}

.header-cashier-exit-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 10%;
}

.exit-icon {
  width: 32px;
  height: 24px;
  cursor: pointer;
}
