.profile-cashier-container {
  margin: 0 auto;
  background-color: white;
}

.profile-cashier-title-container {
  width: 20%;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.profile-cashier-title {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
}

.profile-cashier-subtitle {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
}

.profile-cashier-subtitle-light {
  font-weight: lighter;
}

.profile-cashier-data-container {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.profile-cashier-data {
  height: 60px;
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-picker {
  width: 170px;
  height: 30px;
}

.textField {
  width: 170px;
  height: 30px;
}

.editIcon {
  width: 15px;
  height: 13px;
  margin-left: 10px;
  margin-right: -25px;
  margin-top: 10px;
  cursor: pointer;
}

.profile-cashier-button {
  width: 100%;
  max-width: 250px;
  margin-top: 30px;
}

.profile-help-password-container {
  max-width: 295px;
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: -28px;
  margin-left: 180px;
  margin-right: -150px;
}
.profile-help-password-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -15px;
}

.profile-help-circle {
  width: 12.2px;
  height: 12.2px;
  padding-left: 2px;
  padding-right: 6px;
}
.profile-help-password-text {
  font-family: "Exo 2", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #5c5757;
}

.error-profile-container {
  display: flex;
  position: absolute;
}
