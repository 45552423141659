.breadcrumbs-main-container {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -25px;
}

.breadcrumbs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 90%;
  width: 14%;
  border: none;
  max-width: 95px;
  cursor: pointer;
}

.breadcrumbs {
  height: 18px;
  width: 18px;
}

.center-container {
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.back-arrow-container {
  padding: 0;
  height: 90%;
  width: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
}
.back-arrow {
  margin-top: -2.5px;
  height: 24px;
  width: 24px;
}

.breadcrumb-asset-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  border: none;
}

.middle-breadcrumb {
  justify-content: center;
}

.last-breadcrumb {
  justify-content: flex-start;
}

.breadcrumb-separator {
  display: flex;
  height: 2px;
  flex: 1;
  background-color: #707070;
}

.blue-separator {
  background-color: #ebff00;
}
.breadcrumb-pusher {
  display: flex;
  height: 100%;
  flex: 1;
}

.guide-text {
  display: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
}

@media screen and (min-width: 992px) {
  .breadcrumbs-main-container {
    height: 105px;
    margin-bottom: 0px;
  }

  .breadcrumbs {
    height: 26px;
    width: 26px;
  }
  .back-arrow {
    margin-top: -5px;
    height: 40px;
    width: 40px;
  }
  .guide-text {
    display: inline;
  }
}
