.beneficiaryForm-container {
  width: 100%;
}
.title-beneficiaryForm {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fdfbfb;
  margin-top: 20px;
  margin-bottom: 35px;
}
.text-beneficiaryForm {
  width: 151px;
  height: 24px;
  font-family: "Exo 2", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: left;
  color: #fdfbfb;
  margin-top: 15px;
}

.inputs-container-beneficiaryForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.dropdown-container-beneficiaryForm {
  margin-top: 28px;
  margin-bottom: 30px;
}

.error-beneficiaryForm {
  max-width: 270px;
  margin: 0 auto;
}
.error-text-beneficiaryForm {
  margin-top: 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #fd2d00;
  text-align: center;
}
.error-beneficiaryForm-container {
  margin-top: -40px;
  height: 30px;
  width: 290px;
}

.error-text-beneficiaryForm-container {
  font-family: "Exo 2", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #fd2d00;
}

.beneficiaryForm-check-circle {
  width: 24px;
  height: 25px;
  margin-top: 10px;
  margin-right: 5px;
}
.radio-buttons-container-beneficiaryForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.radio-button-container-beneficiaryForm {
  width: 250px;
  display: flex;
  flex-direction: row;
}
.radio-button-beneficiaryForm {
  width: 30%;
  display: flex;
  flex-direction: row;
}

.subtitle-container-beneficiaryForm {
  margin-top: -25px;
}
.account-subtitle-container-beneficiaryForm {
  margin-top: -35px;
  margin-bottom: -10px;
}

.dropdown-short-container-beneficiaryForm {
  min-width: 270px;
  max-width: 270px;
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: -8px;
}

.input-beneficiaryForm {
  height: 65px;
}
.beneficiaryForm-button-container {
  width: 100%;
  max-width: 270px;
  margin-top: -30px;
}

.dropdown-button-container {
  margin-top: 10px;
}
.beneficiary-list-container {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

/* Beneficiary drop down*/
.beneficiary-single-value-container {
  box-sizing: border-box;
  display: flex;
  height: 30px;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  padding-left: 10px;
  font-family: "Exo 2", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  font-size: 14px;
}

.beneficiary-container-option {
  display: flex;
  flex-direction: row;
  flex: 1;
  border: 1px solid #2a2c3c;
  background-color: #2a2c3c;
  font-weight: 600;
  font-size: 14px;
}

.beneficiary-container-option:hover {
  cursor: pointer;
  background-color: #21222c;
}

.beneficiary-info-container-option {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  border-bottom: 1px solid #ffffff;
}

.beneficiary-last-children-fix {
  border-bottom: none;
}

.beneficiary-info-container-option p {
  margin-left: 8px;
}

.beneficiary-holder-text {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
}

.beneficiary-bank-text {
  margin-left: 10px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
}

.beneficiary-resume-container {
  margin-top: 20px;
  display: flex;
  flex: 1 0 100%;
  width: 90%;
  flex-direction: column;
}

.beneficiary-resume-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.beneficiary-resume-row-label-container {
  display: flex;
  flex: 1.5;
  justify-content: flex-start;
  padding-left: 2%;
}

.beneficiary-resume-label-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
}

.beneficiary-resume-row-value-container {
  display: flex;
  flex: 2;
  justify-content: flex-end;
  padding-right: 2%;
}

.beneficiary-resume-text {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
}
@media screen and (min-width: 992px) {
  .title-beneficiaryForm {
    font-size: 20px;
  }
  .beneficiaryForm-button-container {
    margin-top: -8px;
  }
  .subtitle-container-beneficiaryForm {
    margin-top: -38px;
  }
  .inputs-container-beneficiaryForm {
    width: 740px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .radio-buttons-container-beneficiaryForm {
    width: 740px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .beneficiaryForm-check-circle {
    width: 25%;
    height: 25%;
  }
  .radio-button-container-beneficiaryForm {
    width: 88%;
    display: block;
    float: right;
    flex-direction: row;
    margin-left: -10px;
  }
  .radio-button-beneficiaryForm {
    width: 15%;
    display: flex;
    margin-top: -50px;
    margin-left: 145px;
    float: left;
    flex-direction: row;
  }
  .radio-button-beneficiaryForm:last-child {
    margin-left: -10px;
  }
  .radio-button-beneficiaryForm:nth-child(2) {
    margin-left: -10px;
  }
  .text-beneficiaryForm {
    font-size: 14px;
    margin-top: 13px;
  }
  .beneficiary-holder-text {
    font-size: 14px;
  }
  .beneficiary-bank-text {
    font-size: 14px;
  }

  .beneficiary-resume-container {
    width: 45%;
  }
  .beneficiary-resume-text {
    font-size: 15px;
  }
  .beneficiary-resume-label-text {
    font-size: 15px;
  }
  .dropdown-button-container {
    margin-top: 40px;
  }
}
