.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-image: url("../../../assets/img/fondo-azul.png");
  background-size: auto;
  background-repeat: initial;
  animation-name: displacement;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.login-header {
  text-align: center;
  margin-top: 93px;
}

.login-logo {
  width: 120.6px;
  height: 87.3px;
}

.subTitle-login {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fdfbfb;
  margin-top: 20px;
  margin-bottom: 35px;
}

.title-login {
  width: 279px;
  height: 48px;
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.title-container-login {
  width: 97%;
  max-width: 259px;
  min-width: 299px;
  height: 40px;
  border-radius: 36px;
  background-color: #7234e9;
  text-align: center;
}

.title-shadow-container-login {
  width: 95%;
  max-width: 269px;
  min-width: 310px;
  height: 52px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  background-color: rgba(114, 52, 233, 0.24);
}

.inputs-login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.help-login-container {
  width: 290px;
}

.text-login {
  text-decoration: none;
  color: white;
  margin-top: 10px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.text-login-hover:hover {
  color: #ebff00;
}

.button-login {
  width: 290px;
  max-width: 290px;
  margin: 0 auto;
  margin-top: -10px;
}

.login-check-circle {
  width: 12.2px;
  height: 12.2px;
}

.login-check-container {
  width: 13px;
  margin-right: 5px;
  float: left;
}
.error-login-container {
  width: 290px;
  margin-top: -25px;
}

.error-text-login {
  font-family: "Exo 2", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #fd2d00;
}

@media screen and (min-width: 992px) {
  .login-check-circle {
    width: 17px;
    height: 18px;
  }

  .login-check-container {
    width: 18px;
  }
}

@keyframes displacement {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
}
