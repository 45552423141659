.history-list-main-container {
  max-width: 85%;
  min-width: 320px;
  margin: 30px auto 10px auto;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.history-list-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #d3d3d3;
}

.history-list-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: 0 solid #d3d3d3;
}

.history-list-left-section {
  width: 15%;
  display: flex;
  justify-content: center;
  border-radius: 6px;
}
.blue-chevron-style {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.history-list-right-section {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.transaction-item-row-item {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.transaction-history-label {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #262323;
  margin-left: 15px;
}
.transaction-history-value {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
  margin-right: 15px;
}

.transaction-history-status {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #262323;
  margin-right: 15px;
}

.transaction-history-see-more-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.transaction-history-see-more {
  font-family: "Exo 2", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  margin-right: 50px;
  text-align: right;
  color: #ffffff;
}

.status-blue-color {
  font-weight: bold;
  color: #7234e9 !important;
}
