.header {
  width: 100%;
  min-width: 320px;
  height: 70.8px;
  margin-top: 20px;
}

.logo-container {
  float: left;
  width: 40%;
}

.logo {
  margin: 0 auto;
  margin-left: 25px;
  margin-top: 5px;
  width: 78.5px;
  height: 56.8px;
}

.menu-container {
  float: left;
  width: 60%;
}
.menu-box-1 {
  float: right;
  width: 80px;
  margin: 0px auto;
  height: 60.8px;
  margin-top: 5px;
  background-color: rgba(114, 52, 233, 0.46);
  border-radius: 100px 0px 0px 100px;
}

.menu-box-2 {
  float: right;
  width: 93%;
  height: 52.8px;
  margin-top: 4px;
  background-color: rgba(114, 52, 233, 0.71);
  border-radius: 100px 0px 0px 100px;
}

.menu-box-3 {
  float: right;
  position: relative;
  width: 93%;
  margin-top: 4px;
  height: 44.8px;
  background-color: #7234e9;
  border-radius: 100px 0px 0px 100px;
}

.menu {
  display: none;
}

.hamburger-menu-container {
  border-radius: 5px;
  margin-top: 30px;
  width: 250px;
  height: 270px;
  margin-left: -205px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.71);
  background-color: #7234e9;
}
.hamburger-menu-container ul {
  text-align: right;
  float: right;
  width: 230px;
  margin: 0px auto;
}

.hamburger-menu-container ul li {
  list-style: none;
  height: 35px;
  margin-right: 20px;
  border-bottom: 1px solid #2a2c3c;
  margin-top: 15.1px;
  margin-bottom: 12.3px;
}

.hamburger-menu-container ul li:last-child {
  border: none;
}

.hamburger-menu-container ul li a,
.register {
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: white;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}

.hamburger-menu-container ul li a:hover {
  color: #2a2c3c;
}

.hamburger-menu {
  display: block;
  float: right;
  width: 80%;
  height: 50px;
  text-align: center;
}

.hamburger {
  margin: 0px auto;
  margin-top: 10px;
  width: 30px;
  height: 25px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #7234e9;
  float: right;
  margin-top: -10px;
}

@media screen and (min-width: 992px) {
  .header {
    width: 100%;
    min-width: 848px;
    height: 72px;
  }

  .logo-container {
    float: left;
    width: 40%;
  }

  .logo {
    margin: 0 auto;
    margin-left: 10%;
    margin-top: 5px;
    width: 80px;
    height: 60px;
  }

  /* Menu desplegable */

  nav > ul > li > ul {
    display: none;
    position: absolute;
    z-index: 1;
  }

  nav > ul > li:hover > ul {
    display: block;
    border-radius: 5px;
    background-color: #44277b;
    width: 90px;
    margin-left: 0px;
  }

  nav > ul > li:hover > ul > li {
    width: 115px;
    border-bottom: 1px solid rgb(131, 131, 131);
    margin-left: -30px;
    transition: 500ms;
  }

  nav > ul > li:hover > ul > li:last-child {
    border-bottom: none;
  }

  .menu-container {
    float: left;
    width: 60%;
  }
  .menu-box-1 {
    float: right;
    width: 660px;
    margin: 0px auto;
    height: 72px;
    margin-top: 0px;
    background-color: rgba(114, 52, 233, 0.46);
    border-radius: 100px 0px 0px 100px;
  }

  .menu-box-2 {
    float: right;
    width: 98.9%;
    height: 65px;
    margin-top: 3.5px;
    background-color: rgba(114, 52, 233, 0.71);
    border-radius: 100px 0px 0px 100px;
  }

  .menu-box-3 {
    float: right;
    width: 98.9%;
    margin-top: 5px;
    height: 55px;
    background-color: #7234e9;
    border-radius: 100px 0px 0px 100px;
  }

  .menu {
    display: block;
  }

  .hamburger-menu {
    display: none;
  }

  .hamburger-menu-container {
    display: none;
  }

  .menu ul {
    float: right;
    width: 96%;
    margin-top: 10px;
    margin-right: 10px;
  }

  .menu ul li {
    display: block;
    float: left;
    height: 35px;
    margin: 0px auto;
    margin-left: 10px;
    font-size: 25px;
    line-height: 25px;
    list-style: none;
  }

  .menu ul li a,
  .sub-menu,
  .register {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    margin: 0px 12px 0px 12px;
    cursor: pointer;
    font-family: "Exo 2", sans-serif;
  }

  .sub-menu li a {
    font-size: 12px !important;
  }

  .menu ul li a:hover,
  .sub-menu:hover {
    color: #44277b;
  }

  .sub-menu li a:hover {
    color: #eeff32 !important;
  }

  .login-options {
    font-family: "Exo 2", sans-serif;
    font-size: 11.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.12px;
    color: white;
  }

  .register {
    width: 139.7px;
    height: 35.8px;
    border-radius: 63px;
    background-color: #2f3245;
    color: white;
    border: none;
  }

  .register:hover {
    transform: scale(1.1, 1.1);
  }

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 65px;
    font-family: "Exo 2", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.12px;
    text-align: center;
    color: #2a2c3c;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 130%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;

    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
