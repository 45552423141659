.auth-header-container {
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: transparent;
}

.auth-header-logo-container {
  display: flex;
  height: 70px;
  flex: 1 0 100%;
  order: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.auth-header-regards {
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: left;
  color: #fdfbfb;
}

.auth-header-menu-container {
  display: flex;
  height: 70px;
  flex: 1 0;
  justify-content: flex-end;
}
.auth-header-menu-icon-container {
  height: 100%;
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.auth-header-logo {
  margin: 0 auto;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 5px;
  width: 77.5px;
  height: 56.1px;
}

.user-regular {
  margin-right: 2.5px;
  width: 21px;
  height: 24px;
}

.chevron-arrow {
  margin-right: 20px;
  width: 30px;
  height: 24px;
}

/* Menu */
.auth-menu-container {
  border-radius: 5px;
  margin-top: 55px;
  width: 200px;
  height: 180px;
  margin-left: -216px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.71);
  background-color: #7234e9;
  z-index: 1;
}
.auth-menu-container ul {
  text-align: right;
  float: right;
  width: 230px;
  margin: 0px auto;
}

.auth-menu-container ul li {
  width: 80%;
  margin-left: 35px;
  list-style: none;
  height: 25px;
  margin-right: 20px;
  border-bottom: 1px solid #2a2c3c;
  margin-top: 15.1px;
  margin-bottom: 12.3px;
}

.auth-menu-container ul li:last-child {
  border: none;
}
.auth-menu-container ul li a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: white;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}

.auth-menu-div {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: white;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}

.auth-menu-div:hover {
  color: #2a2c3c;
}

.auth-menu-container ul li a:hover {
  color: #2a2c3c;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #7234e9;
  float: right;
  margin-top: -10px;
}

.auth-menu {
  display: block;
  float: right;
  z-index: 1;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .auth-menu-container {
    margin-top: 65px;
  }

  .auth-header-regards {
    height: 20px;
    margin-right: 10px;
    font-size: 20px;
  }
  .auth-header-logo-container {
    order: 0;
    flex: 1 0 auto;
    height: 90px;
    justify-content: flex-start;
    margin-left: 20px;
    margin-bottom: 0px;
  }
  .auth-header-menu-container {
    flex: 1 0 auto;
    height: 90px;
  }

  .auth-header-menu-icon-container {
    cursor: pointer;
  }

  .auth-menu {
    cursor: pointer;
  }
  .chevron-arrow {
    margin-right: 42px;
  }
  .auth-menu-container {
    margin-left: -236px;
  }
}
