.register-container {
  width: 100%;
  margin-bottom: 50px;
  margin-top: -50px;
}
.register-header {
  text-align: center;
  margin-top: 93px;
}

.register-logo {
  width: 120.6px;
  height: 87.3px;
}

.subTitle-register {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fdfbfb;
  margin-top: 20px;
  margin-bottom: 35px;
}

.inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.dropdown-container {
  min-width: 270px;
  max-width: 270px;
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: -8px;
  margin-bottom: 20px;
}

.text-terms-register {
  margin-top: 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
}

.link-text-terms-register {
  text-decoration: none;
  color: #ebff00;
}

.text-light-terms-register {
  color: #ffffff;
}

.error-register {
  max-width: 270px;
  margin: 0 auto;
}

.error-text-register {
  margin-top: 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #fd2d00;
  text-align: center;
}
.button-register {
  max-width: 270px;
  margin: 0 auto;
  margin-top: 30px;
}

.register-check-circle {
  width: 12.2px;
  height: 12.2px;
  margin-right: 10px;
}

.help-password-container {
  max-width: 295px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.help-password-block {
  display: flex;
  height: 30px;
  width: 100%;
  align-items: center;
}
.register-help-circle {
  width: 12.2px;
  height: 12.2px;
  padding-left: 2px;
  padding-right: 6px;
}
.help-password-text {
  font-family: "Exo 2", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

/* .help-password-text-aux {
  margin-top: 25px;
} */

.error-register-container {
  margin-top: -40px;
  height: 20px;
}

.error-register-container-aux {
  margin-top: -49px;
  height: 20px;
  margin-bottom: 8px;
}

.error-text-register-container {
  font-family: "Exo 2", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #fd2d00;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: -20px;
}

.css-1dqzv00-control {
  box-shadow: none !important;
}

.css-1dqzv00-control:hover {
  box-shadow: none !important;
  border-color: white !important;
}

.css-1ffauvf-control {
  box-shadow: none !important;
}

.css-1ffauvf-control:hover {
  box-shadow: none !important;
  border-color: white !important;
}

.css-1v3kqf4-control {
  box-shadow: none !important;
}

.css-1v3kqf4-control:hover {
  box-shadow: none !important;
  border-color: white !important;
}

.css-26o5i-control {
  box-shadow: none !important;
}

.css-26o5i-control:hover {
  box-shadow: none !important;
  border-color: white !important;
}

@media screen and (min-width: 992px) {
  .register-container {
    width: 100%;
    margin-bottom: 0px;
    margin-top: -80px;
  }

  .register-header {
    margin-top: 100px;
  }
  .inputs-container {
    width: 740px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .text-terms-register {
    padding-left: 20px;
  }

  .register-check-circle {
    width: 18px;
    height: 18px;
    position: absolute;
    margin-left: -30px;
  }
}
