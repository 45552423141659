.home-container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-image: url("../../../assets/img/fondo-azul.png");
  background-size: auto;
  background-repeat: initial;
  animation-name: displacement;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.home-main-container {
  width: 100%;
  margin: 0 auto;
}

.home-content-container {
  margin: 0 auto;
  max-width: 1019px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Exo 2", sans-serif;
}

.home-title {
  margin-top: 1rem;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: lighter;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .home-main-container {
    height: 100vh;
  }
  .home-title {
    font-size: 20px;
  }
  .no-transaction-text {
    font-size: 20px;
  }
}
