.calculation-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calculator-container {
  width: 88.5%;
  margin-top: 30px;
  max-width: 360px;
  margin-bottom: 30px;
  margin-left: 4%;
  margin-right: 4%;
}

.calculation-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.calculation-text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
}

.calculation-button-container {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .calculator-container {
    width: 40%;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: 500px;
  }

  .calculation-text {
    display: none;
  }
}
