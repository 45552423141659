/* div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */

.summary-body-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.summary-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.summary-title-text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
}

.summary-button-container {
  width: 100%;
  max-width: 270px;
  margin-top: 10px;
}

.transaction-summary-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.summary-transaction-info-container {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
}
.summary-beneficiary-info-container {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
}
.transaction-summary-title-container {
  padding-left: 7%;
}

.transaction-summary-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  color: #fdfbfb;
  font-family: "Exo 2", sans-serif;
}

.summary-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.summary-text {
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  color: #fdfbfb;
  font-family: "Exo 2", sans-serif;
}

.summary-label-text {
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  color: #ebff00;
  font-family: "Exo 2", sans-serif;
}

.summary-row-label-container {
  display: flex;
  flex: 1.5;
  justify-content: flex-start;
  padding-left: 10%;
}
.summary-row-value-container {
  display: flex;
  flex: 2;
  justify-content: flex-end;
  padding-right: 10%;
}

@media screen and (min-width: 992px) {
  .summary-title-text {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.3px;
  }
  .summary-transaction-info-container {
    max-width: 400px;
    flex: 1 0 auto;
  }
  .summary-button-container {
    margin-top: 50px;
  }

  .summary-beneficiary-info-container {
    flex: 1 0 auto;
    max-width: 400px;
  }
  .summary-text {
    font-size: 14px;
  }
  .summary-label-text {
    font-size: 14px;
  }
  .transaction-summary-title-container {
    padding-left: 10%;
  }

  .transaction-summary-title {
    font-size: 20px;
    font-weight: 600;
  }
}

@media screen and (max-width: 345px) {
  .summary-row-value-container {
    padding-right: 5%;
  }
  .summary-row-label-container {
    padding-left: 7%;
  }

  .transaction-summary-title-container {
    padding-left: 5%;
  }
}
