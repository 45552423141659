.aboutUs {
  width: 100%;
  height: 1250px;
  position: relative;
  margin-top: 30px;
  padding-left: 12px;
}

.file-aboutUs {
  height: 30%;
  text-align: center;
}

.lock-aboutUs {
  height: 30%;
  text-align: center;
}

.link-aboutUs {
  height: 30%;
  text-align: center;
}

.wrap-aboutUs {
  width: 100%;
  height: 100px;
  text-align: center;
}

.subTitle-wrap-aboutUs {
  width: 100%;
  float: left;
}

.title-aboutUs {
  font-family: "Exo 2", sans-serif;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #2a2c3c;
  margin-bottom: 30px;
}

.subTitle-aboutUs {
  width: 426px;
  height: 41px;
  font-family: "Exo 2", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.97;
  letter-spacing: 0.3px;
  text-align: left;
  color: white;
  border-radius: 63px;
  line-height: 35px;
  padding-left: 10px;
  margin-left: 0%;
}

.file-subTitle-aboutUs {
  position: relative;
  max-width: 230px;
  min-width: 230px;
  height: 36px;
  border-radius: 21px;
  background-color: #1d1d1d;
  z-index: 1;
}

.file-subTitle-shadow-aboutUs {
  position: absolute;
  max-width: 230px;
  min-width: 230px;
  height: 42px;
  margin-left: 3.8%;
  margin-top: 15px;
  border-radius: 26px;
  background-color: rgba(42, 44, 60, 0.4);
}

.lock-subTitle-aboutUs {
  position: relative;
  max-width: 310px;
  min-width: 310px;
  height: 41px;
  border-radius: 20px;
  background-color: #1d1d1d;
  z-index: 1;
}

.lock-subTitle-shadow-aboutUs {
  position: absolute;
  max-width: 312px;
  min-width: 312px;
  height: 50px;
  margin-left: 4%;
  margin-top: 14px;
  border-radius: 26px;
  background-color: rgba(42, 44, 60, 0.4);
}

.link-subTitle-aboutUs {
  position: relative;
  max-width: 255px;
  min-width: 255px;
  height: 41px;
  border-radius: 20px;
  background-color: #1d1d1d;
  z-index: 1;
}

.link-subTitle-shadow-aboutUs {
  position: absolute;
  max-width: 255px;
  min-width: 255px;
  height: 50px;
  margin-left: 4%;
  margin-top: 14px;
  border-radius: 26px;
  background-color: rgba(42, 44, 60, 0.4);
}

.link-ref {
  text-decoration: none;
  color: #8048eb;
}

.text-purple {
  color: #8048eb;
}

.text-dark {
  font-weight: 600;
}

.file {
  width: 87.2px;
  height: 66px;
}
.link {
  width: 80.3px;
  height: 80.3px;
}
.lock {
  width: 71.3px;
  height: 84.4px;
}
.text-aboutUs {
  width: 85%;
  margin-top: 0px;
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  text-align: left;
  color: #8d8e93;
  margin-left: 5%;
  background-color: rgba(255, 255, 255, 0.85);
}

@media screen and (max-width: 358px) {
  .subTitle-aboutUs {
    font-size: 14px;
  }

  .file-subTitle-aboutUs {
    max-width: 150px;
  }

  .file-subTitle-shadow-aboutUs {
    min-width: 235px;
    margin-left: 3.8%;
  }

  .lock-subTitle-aboutUs {
    max-width: 285px;
    min-width: 285px;
  }

  .lock-subTitle-shadow-aboutUs {
    min-width: 290px;
    margin-top: 12px;
  }

  .link-subTitle-aboutUs {
    min-width: 180px;
  }

  .link-subTitle-shadow-aboutUs {
    min-width: 260px;
    margin-top: 12px;
    margin-left: 4%;
  }

  .file {
    width: 77.2px;
    height: 56px;
  }
  .link {
    width: 60.3px;
    height: 60.3px;
  }
  .lock {
    width: 51.3px;
    height: 64.4px;
  }
  .text-aboutUs {
    font-size: 16px;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .aboutUs {
    width: 1019px;
    height: 683px;
    margin-top: 60px;
    margin-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .title-aboutUs {
    font-family: "Exo 2", sans-serif;
    width: 892px;
    height: 109px;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #2a2c3c;
    margin-bottom: 10px;
  }

  .subTitle-aboutUs {
    font-family: "Exo 2", sans-serif;
    width: 469px;
    height: 39px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 0.97;
    letter-spacing: 0.3px;
    text-align: left;
    color: #ffffff;
    background-color: #1d1d1d;
    border-radius: 63px;
    line-height: 35px;
    padding-left: 10px;
    margin-left: 20px;
  }

  .file-aboutUs {
    width: 100%;
    height: 190px;
  }

  .wrap-aboutUs {
    width: 10%;
    height: 100px;
    text-align: center;
    float: left;
    margin-top: 20px;
    margin-left: 45px;
  }

  .subTitle-wrap-aboutUs {
    width: 80%;
    height: 90%;
    float: left;
  }

  .lock-aboutUs {
    width: 100%;
    height: 190px;
  }

  .link-aboutUs {
    width: 100%;
    height: 190px;
  }

  .file {
    margin-right: 40px;
    width: 77.2px;
    height: 56px;
  }
  .link {
    margin-right: 40px;
    width: 64px;
    height: 64px;
  }
  .lock {
    margin-right: 40px;
    width: 58.1px;
    height: 66.4px;
  }

  .file-subTitle-aboutUs {
    position: relative;
    max-width: 450px;
    height: 36px;
    border-radius: 21px;
    background-color: #1d1d1d;
    z-index: 1;
  }

  .file-subTitle-shadow-aboutUs {
    position: absolute;
    min-width: 440px;
    max-width: 440px;
    height: 45px;
    margin-left: 4.5%;
    margin-top: 26px;
    border-radius: 26px;
    background-color: rgba(42, 44, 60, 0.4);
  }

  .lock-subTitle-aboutUs {
    position: relative;
    max-width: 580px;
    min-width: 580px;
    height: 41px;
    border-radius: 20px;
    background-color: #1d1d1d;
    z-index: 1;
  }

  .lock-subTitle-shadow-aboutUs {
    position: absolute;
    max-width: 580px;
    min-width: 580px;
    height: 52px;
    margin-left: 3.7%;
    margin-top: 25px;
    border-radius: 26px;
    background-color: rgba(42, 44, 60, 0.4);
  }

  .link-subTitle-aboutUs {
    position: relative;
    max-width: 520px;
    min-width: 520px;
    height: 41px;
    border-radius: 20px;
    background-color: #1d1d1d;
    z-index: 1;
  }

  .link-subTitle-shadow-aboutUs {
    position: absolute;
    max-width: 515x;
    min-width: 515px;
    height: 52px;
    margin-left: 4.1%;
    margin-top: 24px;
    border-radius: 26px;
    background-color: rgba(42, 44, 60, 0.4);
  }

  .text-aboutUs {
    width: 680px;
    height: 50px;
    margin-top: -12px;
    font-family: "Exo 2", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: left;
    /* color: #1d1d1d; */
    margin-left: 4%;
  }
}
