.sidebar-container {
  display: flex;
  flex: 1 0 27%;
  max-width: 280px;
  height: 110vh;
  flex-direction: column;
  background-color: #23232d;
}

.sidebar-user-container {
  height: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 0.2px solid #b6b6b6;
}

.sidebar-user {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 2.5vw;
}

.sidebar-user-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebar-text-light {
  opacity: 0.48;
  font-family: "Exo 2", sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #d1cece;
}

.sidebar-option-container {
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  max-height: 50px;
  height: 50px;
  cursor: pointer;
}

.sidebar-sub-option {
  margin-left: 0px;
}

.sidebar-sub-option:hover {
  background-color: #7234e9;
}

.sidebar-option {
  width: 70%;
  margin-left: 10px;
  margin-top: 4px;
}

.sidebar-text {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.sidebar-radio-icon {
  width: 24px;
  height: 24px;
  margin-left: 3vw;
}

.sidebar-user-icon {
  width: 55px;
  height: 55px;
}

.sidebar-user-light-icon {
  width: 30px;
  height: 30px;
  margin-left: 2.5vw;
}

.sidebar-user-config-icon {
  width: 31px;
  height: 25px;
  margin-left: 2.5vw;
}

.sidebar-arrow-config-icon {
  width: 31px;
  height: 25px;
  margin-right: 10px;
}
.profile-container-margin {
  margin-top: 15px;
}
@media screen and (min-width: 1300px) {
  .sidebar-container {
    max-width: 350px;
  }
}
