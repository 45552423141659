.payment-container {
  width: 100%;
}
.inputs-container-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.banner {
  display: inline-flex;
  width: 100%;
  height: 55px;
  background-color: #7234e9;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.diary-container {
  margin-left: 10px;
  width: 50px;
}

.text-banner {
  width: auto;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #fdfbfb;
}
.text-bold-banner {
  font-weight: bold;
}
.diary {
  width: 20px;
  height: 20px;
}

.list-payment {
  width: 290px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title-payment {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fdfbfb;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subTitle-payment {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.3px;
  text-align: left;
  color: #fdfbfb;
  width: 310px;
}
.text-payment {
  height: 40px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: left;
  color: #fdfbfb;
}

.text-bold-payment {
  font-weight: bold;
}

.text-blue-payment {
  font-weight: bold;
  color: #ebff00;
}

.input-container-payment {
  margin-top: -30px;
}

.question-container-payment {
  display: flex;
}
.question-payment {
  margin-top: 15px;
  width: 13px;
  height: 13px;
  margin-left: 5px;
}
.capsule-payment {
  display: none;
}
.summary-payment {
  margin-top: -20px;
}

.payment-button-container {
  width: 100%;
  max-width: 270px;
  margin-top: -30px;
}

/* ToolTip */

/* Tooltip container */
.tooltip-payment {
  position: relative;
  display: inline-block;
}

.tooltiptext-payment {
  display: initial;
}
.tooltiptext-bold-payment {
  font-weight: bold;
}

/* Tooltip text */
.tooltip-payment .tooltiptext-payment {
  visibility: hidden;
  width: 180px;
  background-color: white;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-family: "Exo 2", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  text-align: left;
  color: #2a2c3c;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: -30%;
  left: 60%;
  margin-left: 15px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-payment .tooltiptext-payment::after {
  content: "";
  position: absolute;
  bottom: 50%;
  right: 100%;

  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-payment:hover .tooltiptext-payment {
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 992px) {
  .inputs-container-payment {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .payment-button-container {
    margin-top: -8px;
  }
  .title-payment {
    display: none;
  }

  .subTitle-payment {
    font-size: 20px;
    width: 700px;
    letter-spacing: 1px;
    text-align: left;
  }

  .container-row-payment {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  .dropdown-container-payment {
    padding-top: 10px;
  }

  .summary-container-payment {
    width: 100%;
  }

  .input-container-payment {
    margin-top: 0;
  }
  .list-payment {
    width: 85%;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .summary-value-payment {
    width: 50%;
  }
  .capsule-payment {
    display: inline;
    margin-top: 15px;
    width: 84.8px;
    height: 25.3px;
    margin-right: 15px;
  }
  .capsule-rotate-payment {
    transform: rotate(180deg);
  }
  .text-blue-payment {
    font-size: 20px;
    margin-top: 10px;
  }

  .question-payment {
    margin-top: 15px;
    width: 16px;
    height: 16px;
  }

  .banner {
    width: 100%;
    height: 40px;
  }
  .diary-container {
    width: 40px;
  }
  .text-banner {
    font-size: 16px;
  }
}
