/* Inicio Button */
.button-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem 0;
}

.button-container :hover {
  cursor: pointer;
}

.common-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #7234e9;
  color: white;
  font-size: 12px;
  height: 40px;
  border-radius: 63px;
  font-weight: 700;
  object-fit: contain;
  border: none;
  outline-style: none;
}

.common-secondary-button {
  border: solid 3px #7234e9;
  background-color: white;
  color: black;
}

.common-button-disabled {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #dddddd;
  color: #ffffff;
  font-size: 14px;
  height: 3.2rem;
  border-radius: 63px;
  font-weight: 700;
  object-fit: contain;
  border: none;
}

/* Fin Button */

/* Inicio Input */
.common-input {
  width: 70%;
  max-width: 326px;
  min-width: 280px;
  height: 40px;
  margin-bottom: 30px;
  background-color: rgba(227, 227, 227, 0.35);
  border: none;
  border-bottom: 1px solid white;
  border-radius: 2px 2px 0px 0px;
  padding-left: 10px;
  color: white;
  box-shadow: none;
  outline: none;
}

.common-input-disabled {
  opacity: 0.4;
}

.common-input-invalid {
  border-bottom: 1px solid red;
}

.common-input-valid {
  border-bottom: 1px solid #ebff00;
}

.css-anrk67-container {
  margin-right: -69px !important;
}

.common-input-dropdown {
  position: relative;
  max-width: 280px;
  min-width: 168px;
}

.common-input-dropdown-calling {
  max-width: 280px;
  min-width: 170px;
  margin-left: 10px;
}
input.common-input::placeholder {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  color: #bababa;
}

.eye-icon {
  width: 17.6px;
  height: 12px;
  position: absolute;
  margin-left: -38px;
  margin-top: 15px;
  z-index: 0;
}
/* Fin Input */

/* country drop down*/
.country-dropdown-single-value-container {
  box-sizing: border-box;
  display: flex;
  height: 30px;
  flex-direction: row;
  align-items: center;
  color: #bababa;
  padding-left: 10px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
}

.country-dropdown-single-value-flag {
  width: 20px;
  height: 12px;
  object-fit: contain;
  margin-top: 2px;
  margin-left: 8px;
}

.country-dropdown-single-value-flag-fix-small {
  margin-left: 1rem;
  width: 25px;
  height: 16px;
  object-fit: contain;
}

.country-dropdown-container-option {
  display: flex;
  flex-direction: row;
  flex: 1;
  border: 1px solid #2a2c3c;
  background-color: #2a2c3c;
  font-weight: 600;
  font-size: 14px;
  color: #bababa;
  padding-left: 10px;
  font-family: "Exo 2", sans-serif;
}

.country-dropdown-container-option:hover {
  cursor: pointer;
  background-color: #21222c;
}

.country-dropdown-info-container-option {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 96%;
  border-bottom: 1px solid #ffffff;
}

.country-dropdown-last-children-fix {
  border-bottom: none;
}
.country-dropdown-country-name-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: lighter;
  font-size: 14px;
  color: #bababa;
  font-family: "Exo 2", sans-serif;
}

.country-dropdown-flag {
  width: 22px;
  height: 12px;
  object-fit: contain;
  margin-top: 2px;
  margin-left: 8px;
}

.country-dropdown-info-container-option p {
  margin-left: 8px;
}
/*    fin country drop dow     */

/* generic drop down*/
.dropdown-single-value-container {
  box-sizing: border-box;
  display: flex;
  height: 30px;
  flex-direction: row;
  align-items: center;
  color: #bababa;
  padding-left: 10px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
}

.dropdown-single-value-flag {
  width: 20px;
  height: 12px;
  object-fit: contain;
  margin-top: 2px;
  margin-left: 8px;
}

.dropdown-single-value-flag-fix-small {
  margin-left: 1rem;
  width: 25px;
  height: 16px;
  object-fit: contain;
}

.dropdown-container-option {
  display: flex;
  flex-direction: row;
  flex: 1;
  border: 1px solid #2a2c3c;
  background-color: #2a2c3c;
  font-weight: 600;
  font-size: 14px;
}

.dropdown-container-option:hover {
  cursor: pointer;
  background-color: #21222c;
}

.dropdown-info-container-option {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 96%;
  border-bottom: 1px solid #ffffff;
}

.dropdown-last-children-fix {
  border-bottom: none;
}

.dropdown-info-container-option p {
  margin-left: 8px;
}

.dropdown-label-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: lighter;
  font-size: 14px;
  color: #bababa;
  font-family: "Exo 2", sans-serif;
}
.custom-dropdown-option {
  color: #ebff00;
  font-weight: bold;
}

/*    fin generic drop dow     */
/* loader */

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: block;
  z-index: 1;
}

.loader-main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.body-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55%;
  justify-content: center;
}

/* Fin loader */

/* No transaction Component */

.no-transaction-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.no-transaction-white-line {
  margin-top: 20px;
  width: 85%;
  border-top: solid 2px white;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.no-transaction-gray-line {
  margin-top: 20px;
  width: 85%;
  border-top: solid 2px rgb(209, 206, 206);
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.no-transaction-text {
  font-family: "Exo 2", sans-serif;
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: left;
  color: #ebff00;
}

.no-transaction-cashier {
  color: #7234e9;
}

/* FIN No transaction Component */

@media screen and (min-width: 420px) {
  .common-button {
    max-width: 300px;
    height: 3.2rem;
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  .country-dropdown-single-value-container {
    padding-left: 3px;
  }

  .dropdown-single-value-container {
    padding-left: 3px;
  }
  .no-transaction-white-line {
    width: 100%;
  }
  .no-transaction-gray-line {
    width: 100%;
  }
}
